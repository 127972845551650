<template>
  <div>
    <div class="heading-sec">
      <div class="row">
        <div class="col-md-4 column">
          <div class="heading-profile">
            <div class="font-24 mt-10 ml-4">Create Collection</div>
          </div>
        </div>
      </div>
    </div>
    <!-- Top Bar Chart -->
    <v-card class="mt-2 mx-4">
      <div class="panel-content">
        <div class="row">
          <div class="col-md-12">
            <div class="widget">
              <div class="form-elements-sec">
                <div class="form-body">
                  <form
                    name="details"
                    class="formPart text-left"
                    @submit.prevent="submitForm(details)"
                  >
                    <!-- <div>
                      <div class="form-group col">
                        <label for="email">Name:</label>
                        <v-select
                          prepend-icon=""
                          item-text="name"
                          v-model="details.name"
                          :items="brands"
                          class="form-control"
                          append-icon="▼"
                        >
                          <template v-slot:selection="{ item }">
                            <v-row no-gutters>
                              <v-col cols="12">
                                <div>{{ item.brandName }}</div>
                              </v-col>
                            </v-row>
                          </template>
                          <template v-slot:item="{ item, attrs, on }">
                            <v-list-item v-on="on" v-bind="attrs">
                              <v-list-item-content>
                                <div>{{ item.brandName }}</div>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-select>
                        <div
                          class="validation"
                          v-if="!$v.details.name.required && $v.details.name.$error"
                        >
                          Designer Name is required
                        </div>
                      </div>
                    </div> -->

                    <div class="d-flex">
                      <div class="form-group col">
                        <div>
                          <label for="image">Mobile Image:</label>
                          <div class="font-12 black--text font-weight-bold">
                            (376 W &#10005; 209 H)
                          </div>
                          <v-file-input
                            prepend-icon=""
                            type="file"
                            class="form-control"
                            id="banner-image"
                            v-model.trim="details.imageMobile[0]"
                            placeholder=" Select Image"
                            accept="image/jpeg, image/png"
                            @change="handleMobileImage"
                          ></v-file-input>
                          <div
                            class="validation"
                            v-if="!$v.details.imageMobile.required && $v.details.imageMobile.$error"
                          >
                            Mobile Image is required
                          </div>
                        </div>
                        <div class="preview-image border p-2 mt-3 ml-3" v-if="mobileUrl">
                          <v-img :src="mobileUrl" height="230" width="100%" contain></v-img>
                        </div>
                      </div>

                      <div class="form-group col">
                        <div>
                          <label for="image"> Desktop Image:</label>
                          <div class="font-12 black--text font-weight-bold">
                            (720 W &#10005; 377 H)
                          </div>
                          <v-file-input
                            prepend-icon=""
                            type="file"
                            class="form-control"
                            id="banner-image"
                            v-model.trim="details.image[0]"
                            placeholder=" Select Image"
                            @change="handleDesktopImage"
                            accept="image/jpeg, image/png"
                          ></v-file-input>
                          <div
                            class="validation"
                            v-if="!$v.details.image.required && $v.details.image.$error"
                          >
                            Desktop Image is required
                          </div>
                        </div>
                        <div class="preview-image border p-2 mt-3 mr-3" v-if="desktopUrl">
                          <v-img :src="desktopUrl" height="230" contain></v-img>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div class="form-group col">
                        <label for="link"
                          >Palettier SKU Codes:
                          <v-btn @click="addSku" dense>Add</v-btn>
                        </label>

                        <div class="poppins-medium font-16 pl-4 d-block">
                          <v-row no-gutters>
                            <v-col
                              cols="6"
                              v-for="(item, index) in details.query"
                              :key="index"
                              class=""
                            >
                              <v-text-field
                                v-model="item.sku"
                                width="200px"
                                outlined
                                dense
                                class="mt-6 mr-4 px-2"
                                >{{ item.sku }}</v-text-field
                              >
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="form-group col-6">
                        <div>
                          <label for="image">Mobile Banner Image:</label>
                          <div class="font-12 black--text font-weight-bold">
                            (163 W &#10005; 221 H)
                          </div>
                          <v-file-input
                            prepend-icon=""
                            type="file"
                            class="form-control"
                            id="banner-image"
                            v-model.trim="details.mobileBannerImage"
                            placeholder=" Select Image"
                            accept="image/jpeg, image/png"
                            @change="handleMobileBannerImage"
                          ></v-file-input>
                          <div
                            class="validation"
                            v-if="
                              !$v.details.mobileBannerImage.required &&
                              $v.details.mobileBannerImage.$error
                            "
                          >
                            Mobile Banner Image is required
                          </div>
                        </div>
                        <div class="preview-image border p-2 mt-3 ml-3" v-if="mobileBannerUrl">
                          <v-img :src="mobileBannerUrl" height="230" width="100%" contain></v-img>
                        </div>
                      </div>

                      <div class="form-group col-6">
                        <div>
                          <label for="image"> Desktop Banner Image:</label>
                          <div class="font-12 black--text font-weight-bold">
                            (305 W &#10005; 414 H)
                          </div>
                          <v-file-input
                            prepend-icon=""
                            type="file"
                            class="form-control"
                            id="banner-image"
                            v-model.trim="details.bannerImage"
                            placeholder=" Select Image"
                            @change="handleDesktopBannerImage"
                            accept="image/jpeg, image/png"
                          ></v-file-input>
                          <div
                            class="validation"
                            v-if="!$v.details.bannerImage.required && $v.details.bannerImage.$error"
                          >
                            Desktop Banner Image is required
                          </div>
                        </div>
                        <div class="preview-image border p-2 mt-3 mr-3" v-if="desktopBannerUrl">
                          <v-img :src="desktopBannerUrl" height="230" contain></v-img>
                        </div>
                      </div>
                    </div>
                    <!-- button -->
                    <div class="btnPart text-center my-4">
                      <button type="submit" class="btn btn-success">Save</button>
                      <p class="typo__p" v-if="submitStatus === 'OK'">
                        Thanks for your submission!
                      </p>
                      <p class="typo__p" v-if="submitStatus === 'ERROR'">
                        Please fill the form correctly.
                      </p>

                      <button type="reset" class="btn btn-danger mx-3" v-on:click="resetForm()">
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import homeService from '../../services/homeService';
import imageUploadService from '../../services/imageUploadService';

export default {
  data() {
    return {
      details: {
        imageMobile: [],
        image: [],
        query: [],
        user: '',
        mobileBannerImage: '',
        bannerImage: '',
      },
      submitStatus: null,
      mobileUrl: null,
      desktopUrl: null,
      mobileBannerUrl: null,
      desktopBannerUrl: null,
    };
  },

  validations: {
    details: {
      imageMobile: {
        required,
      },
      image: {
        required,
      },
      query: {
        required,
      },
      mobileBannerImage: {
        required,
      },
      bannerImage: {
        required,
      },
    },
  },

  methods: {
    // this method use for validation error
    setTouch(fields) {
      if (fields === 'mobileImage') {
        this.$v.details.imageMobile[0].$touch();
      } else if (fields === 'image') {
        this.$v.details.image[0].$touch();
      } else if (fields === 'mobileBannerImage') {
        this.$v.details.mobileBannerImage.$touch();
      } else if (fields === 'bannerImage') {
        this.$v.details.bannerImage.$touch();
      }
    },
    // this is use for save button
    async submitForm(details) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR';
        this.$store.commit('setSnackbar', {
          content: 'please fill form correctly',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      } else {
        this.submitStatus = 'OK';
        console.log(details, 'detailss');
        this.details.user = JSON.parse(localStorage.getItem('selectedVendor'));
        const result = await homeService.createDesigner(details);

        if (result.status === 200) {
          this.$store.commit('setSnackbar', {
            content: 'Submitted Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
          this.$router.push('/viewCollections');
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      }
    },
    // this method use for cancel button
    resetForm() {
      this.details = 'blank';
      this.$nextTick(() => {
        this.$v.$reset();
        this.$router.push('/viewCollections');
      });
    },

    async handleDesktopImage(file) {
      this.desktopUrl = URL.createObjectURL(this.details.image[0]);
      imageUploadService.uploadImage(file, (result) => {
        if (result.status === 200) {
          this.$store.commit('setSnackbar', {
            content: 'Image Uploaded Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
          console.log('image', result.data.meta.location);
          this.details.image[0] = result.data.meta.location;
          this.preview = result;
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      });
    },
    async handleMobileImage(file) {
      this.mobileUrl = URL.createObjectURL(this.details.imageMobile[0]);

      imageUploadService.uploadImage(file, (result) => {
        if (result.status === 200) {
          console.log(result.data.meta.location, 'meta');
          this.details.imageMobile[0] = result.data.meta.location;

          console.log(this.details.image, 'meta2');
          this.$store.commit('setSnackbar', {
            content: 'Image Uploaded Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      });
    },
    async handleDesktopBannerImage(file) {
      this.desktopBannerUrl = URL.createObjectURL(this.details.bannerImage);
      imageUploadService.uploadImage(file, (result) => {
        if (result.status === 200) {
          console.log(result.data.meta.location, 'meta');
          this.details.bannerImage = result.data.meta.location;

          this.$store.commit('setSnackbar', {
            content: 'Image Uploaded Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      });
    },
    async handleMobileBannerImage(file) {
      this.mobileBannerUrl = await URL.createObjectURL(this.details.mobileBannerImage);

      imageUploadService.uploadImage(file, (result) => {
        if (result.status === 200) {
          console.log(result.data.meta.location, 'meta');
          this.details.mobileBannerImage = result.data.meta.location;

          this.$store.commit('setSnackbar', {
            content: 'Image Uploaded Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      });
    },
    async addSku() {
      const data = {};
      data.sku = '';
      this.details.query.push(data);
    },
  },
};
</script>
<style lang="scss" scoped>
.validation {
  color: red;
}
.preview-image {
  width: 300px;
  height: 250px;
}
</style>
